import React from 'react'
import DetailsSidebar from './ServicesSidebar'
import details1 from '../../assets/images/services/services-details1.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const EcommerceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        {/* <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div> */}

                        <div className="services-details-desc pt-100">
                            <span className="sub-title">Digital Transformation</span>
                            <h3>Operating Models for eCommerce</h3>
                            <p>E-commerce businesses operate in a highly competitive and rapidly evolving digital environment. Operating models can help e-commerce businesses optimize their processes and systems and improve their performance. Here are a few operating models that are commonly used by e-commerce businesses:</p>

                            <p><strong>Marketplace Model:</strong> This model involves creating a platform where multiple vendors can sell their products and services to customers. The marketplace operator provides the infrastructure, such as a website and payment processing systems, and takes a commission on each transaction. This model is popular because it allows e-commerce businesses to offer a wide range of products and services to customers without having to invest in inventory or fulfillment.</p>
                            
                            <p><strong>Direct-to-Consumer Model:</strong> This model involves selling products and services directly to customers through an e-commerce website. The e-commerce business is responsible for managing all aspects of the customer experience, including product development, marketing, sales, and customer service. This model gives e-commerce businesses more control over the customer experience and allows them to build a strong brand identity.</p>

                            <p><strong>Subscription Model:</strong> This model involves selling products and services to customers on a recurring basis, such as monthly or annual subscriptions. The e-commerce business is responsible for managing all aspects of the customer experience, including product development, marketing, sales, and customer service. This model allows e-commerce businesses to build a steady stream of recurring revenue and can be used to sell products and services such as monthly box subscriptions or online memberships.</p>

                            <p><strong>Hybrid Model:</strong> This model involves combining elements of the marketplace and direct-to-consumer models. For example, an e-commerce business might operate a marketplace for third-party vendors, but also sell its own products and services directly to customers. This model can provide the best of both worlds, allowing e-commerce businesses to offer a wide range of products and services while also controlling the customer experience.</p>
                           
                            <p>These operating models are not mutually exclusive and many e-commerce businesses use a combination of models to meet their specific needs and goals. The best operating model for an e-commerce business will depend on factors such as the products and services it offers, its target customers, and its overall business strategy. E-commerce businesses should carefully evaluate their options and choose the operating model that best fits their needs and goals.</p>

                            {/* <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>We Offer </h3>
                                        <ul>
                                            <li>UI/UX Design</li>
                                            <li>Modular Content Management</li>
                                            <li>Front-End Development</li>
                                            <li>Back-end Development</li>
                                            <li>DevOps</li>
                                            <li>Analytics</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}

                            {/* <h3>E-Commerce Solutions</h3> */}

                            {/* <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Headless eCommerce
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Adobe Magento 
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Shopify eCommerce
                                    </div>
                                </div>
            
                            </div> */}

                            {/* <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <DetailsSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EcommerceDetailsContent