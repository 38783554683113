import React from 'react'
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import Layout from "../../components/App/Layout"
import Banner from '../../components/SEOAgency/Banner'
import FeaturedServices from '../../components/SEOAgency/FeaturedServices'
import AboutArea from '../../components/SEOAgency/AboutArea'
import FunFacts from '../../components/SEOAgency/FunFacts'
import EcommerceModels from '../../components/Insights/EcommerceModels'
import CaseStudy from '../../components/SEOAgency/CaseStudy'
import WhyChooseUs from '../../components/SEOAgency/WhyChooseUs'
import Testimonials from '../../components/SEOAgency/Testimonials'
import Pricing from '../../components/SEOAgency/Pricing'
import AnalysisFormJS from '../../components/Services/AnalysisFormJS'
import Partner from '../../components/Services/Partner'
import OurBlog from '../../components/SEOAgency/OurBlog'
import TeamMembers from '../../components/SEOAgency/TeamMembers'

const Solutions = () => {
    return (
        <Layout>
            <Navbar />
            {/* <Banner />
            <FeaturedServices />
            <AboutArea />
            <FunFacts /> */}
            <EcommerceModels />
            {/* <CaseStudy /> */}
            {/* <WhyChooseUs /> */}
            {/* <Testimonials /> */}
            {/* <Pricing /> */}
            {/* <TeamMembers /> */}
            {/* <AnalysisFormJS /> */}
            {/* <Partner /> */}
            {/* <OurBlog /> */}
            <Footer />
        </Layout>
    )
}

export default Solutions
